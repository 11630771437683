import React, { useContext, useEffect, useState, useRef } from 'react';
import { DataContext } from '../context/dataContext'; 
import { IconButton, Collapse, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Plot from 'react-plotly.js';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Link from '@material-ui/core/Link';

export default function EPRIDICT() {
    
    const container = {
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center', 
        padding: "5% 10%",
        textAlign: 'center'
    }

    const formControl = {
        marginBottom: '1rem',
        minWidth: 120,
    }

    const fieldContainer = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '1rem',
    }

    const descriptionStyle = {
        marginRight: '1rem',
    }
    const [showInfo, setShowInfo] = useState(false);
    const [showBinInfo, setShowBinInfo] = useState(false);
    const [sequence, setSequence] = useState('');
    const [alignmentOpen, setAlignmentOpen] = useState(false); 
    const [selectedAlignment, setSelectedAlignment] = useState("");
    const [fetchCompleted, setFetchCompleted] = useState(false);

    // Adjust to find the bin for prediction
    const findBinForPrediction = (prediction, binEdges) => {
        let binForPrediction = -1;
        for(let i = 0; i < binEdges.length - 1; i++) {
            if(prediction >= binEdges[i] && prediction < binEdges[i + 1]) {
                binForPrediction = i;
                break;
            }
        }
        return binForPrediction;
    }
    

    // define your locations
    const locations = [
        {
            name: 'Location 1 (low)',
            chromosome: 'chr12',
            position: '18208538',
        },
        {
            name: 'Location 2 (medium)',
            chromosome: 'chr3',
            position: '44843504',
        },
        {
            name: 'Location 3 (high)',
            chromosome: 'chr21',
            position: '14762784',
        },
        
    ];


    // initialize the location switches
    const [locationSwitches, setLocationSwitches] = React.useState([false, true, false]);

    const [searchLoading, setSearchLoading] = useState(false); // Loading state for search
    const [submitLoading, setSubmitLoading] = useState(false); // Loading state for submit
    const [invalidSequence, setInvalidSequence] = useState(false); // State for invalid sequence length
    const [searchError, setSearchError] = useState(null); // New state for search errors
    const resetSequenceField = () => {
        setSequence('');
        inputRef.current.value = '';
        setSearchError(null); // Reset search error
    };

    // resets the alignment result if a manual change is performed in the frontend
    const resetAlignmentResult = () => {
        setSelectedAlignment('');
        setAlignmentOpen(false);
    };

    // resets the location switches if a manual change is performed in the frontend
    const resetLocationSwitches = () => {
        setLocationSwitches([false, false, false]);
    };


    const inputRef = useRef(null);


    // Change the fetchEpridictData function to set and unset loading state
    const fetchEpridictDataWithLoading = async () => {
        setSubmitLoading(true);
        // console.log('Loading state after setting to true:', isLoading);
        setEpridictData(null);  // Clear the old data
        const errorMessage = await fetchEpridictData();
        // console.log('Error message:', errorMessage);
        setSubmitLoading(false);
        // console.log('Loading state after setting to false:', isLoading);
        if (!errorMessage) {
            setSubmitted(true);
        }
    };
    
    const handleSearch = async () => {
        const actualSequence = sequence || inputRef.current?.placeholder;
        const isValidSequence = /^[ATGCatgc]{30}$/.test(actualSequence);  // Check for valid DNA sequence
    
        if (actualSequence === "Seq. +/- 15bp from nick") {
            setFetchCompleted(true);
            setInvalidSequence(true);
            setSearchLoading(false);
            setSearchError("Please provide a target sequence to search in the genome.");
            return;
        }
    
        if (!isValidSequence) {
            setInvalidSequence(true);
            setFetchCompleted(true);
            setSearchLoading(false);
            setSearchError("Sequence must be 30 bp long and only contain A, T, G, or C.");
            return;
        }
    
        setInvalidSequence(false);
        setSearchLoading(true);
        setFetchCompleted(false);
        setSearchError(null);
        await fetchAlignmentResults(actualSequence);
        setFetchCompleted(true);
        setSearchLoading(false);
        setAlignmentOpen(true);
    
        // Reset chromosome and position fields
        setChromosome('');
        setPosition('');
    };

    // handle location switch change
    const handleLocationSwitchChange = (index) => {
        let newSwitches = [...locationSwitches];
        if (locationSwitches[index]) {
            newSwitches = newSwitches.map(() => false);
            setLocationSwitches(newSwitches);
            setChromosome("");
            setPosition("");
        } else {
            newSwitches = newSwitches.map(() => false);
            newSwitches[index] = true;
            setLocationSwitches(newSwitches);
            setChromosome(locations[index].chromosome);
            setPosition(locations[index].position);
        }
        resetSequenceField();
        setSearchError(null); // Reset search error
    };
    
    const featureOrder = ['DNase-seq', 'HDAC2', 'H3K4me1', 'H3K4me2', 'H3K27me3', 'H3K9me3'];
    const tooltipDescriptions = ['ENCFF972GVB', 'ENCFF954LGE', 'ENCFF834SEY', 'ENCFF959YJV', 'ENCFF139KZL', 'ENCFF601JGK'];
    const featureLink = ['https://www.encodeproject.org/files/ENCFF972GVB/', 'https://www.encodeproject.org/files/ENCFF954LGE/', 'https://www.encodeproject.org/files/ENCFF834SEY/', 'https://www.encodeproject.org/files/ENCFF959YJV/', 'https://www.encodeproject.org/files/ENCFF139KZL/', 'https://www.encodeproject.org/files/ENCFF601JGK/'];

    const { chromosome, setChromosome, position, setPosition, fetchEpridictData, epridictData, setEpridictData, error, setError, colorScales, fetchAlignmentResults, alignmentResults } = useContext(DataContext);
    const [submitted, setSubmitted] = useState(false);
    
    useEffect(() => {
    }, [epridictData]);
    
    useEffect(() => {
        setSubmitted(false); // Reset the submitted state when input changes
        setError(null); // Reset the error state when input changes
    }, [chromosome, position, setError]);

    return  (
        <div style={container}>
            <div style={{ width: '70%', textAlign: 'center' }}>
                <Typography variant={"h6"} align="center" style={{ marginTop: '-20px', marginBottom: '1rem' }}>
                    ePRIDICT: <span style={{ textDecoration: 'underline' }}>e</span>pigenetic based <span style={{ textDecoration: 'underline' }}>Pri</span>me Editing Efficiency Pre<span style={{ textDecoration: 'underline' }}>dict</span>ion
                </Typography>

                <div style={fieldContainer}>
                    <Typography variant="subtitle1" style={descriptionStyle}>Example locations:</Typography>
                    {locations.map((location, index) => (
                        <FormGroup row key={index} style={{ marginLeft: '1rem' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={locationSwitches[index]}
                                        onChange={() => handleLocationSwitchChange(index)}
                                        color="primary"
                                    />
                                }
                                label={location.name}
                            />
                        </FormGroup>
                    ))}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                    <Typography variant="subtitle1" style={{ marginRight: '1rem' }}>Search for genomic location:</Typography>
                    
                    <FormControl style={{ marginBottom: '0' }}>
                        <TextField
                            id="genomic-location-input"
                            placeholder="Seq. +/- 15bp from nick"
                            type="text"
                            value={sequence}
                            onChange={(e) => {
                                setSequence(e.target.value);
                                setChromosome(''); // Clear chromosome field
                                setPosition(''); // Clear position field
                                resetAlignmentResult(); // Reset alignment result
                                resetLocationSwitches(); // Reset location switches
                                setSearchError(null); // Reset search error

                            }}
                            InputProps={{
                                style: {
                                    color: sequence ? 'black' : 'grey' // Black for input text, grey for placeholder
                                }
                            }}
                            inputRef={inputRef}
                        />
                    </FormControl>
                    <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={handleSearch}>
                        Search in genome (human, hg38)
                    </Button>
                    <Tooltip title={<Typography style={{fontSize: '1rem'}}>Input format: Genomic sequence 15 bp up- and downstream of Cas9 nick position. Total length of input sequence needs to be 30 bp.</Typography>} arrow placement="top">
                        <IconButton style={{ padding: 0}}> 
                            <InfoIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </div>

                {searchLoading && (
                    <Typography variant="subtitle1" style={descriptionStyle}>Searching...</Typography>
                )}

                {!searchLoading && fetchCompleted && searchError && (
                    <Typography variant="subtitle1" style={{ ...descriptionStyle, color: 'red' }}>
                        {searchError}
                    </Typography>                
                )}

                {!searchLoading && fetchCompleted && !invalidSequence && alignmentResults.length === 0 && (
                    <Typography variant="subtitle1" style={{ ...descriptionStyle, color: 'red' }}>
                        No match found in human genome (hg38).
                    </Typography>
                )}


                {!searchLoading && alignmentResults.length > 0 && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1rem', zIndex: 1 }}>
                        <Typography variant="subtitle1" style={descriptionStyle}>Select Alignment Result:</Typography>
                        <FormControl style={{ minWidth: 200 }}>
                            <Select
                                labelId="alignment-results-select-label"
                                id="alignment-results-select"
                                value={selectedAlignment}
                                onChange={(e) => {
                                    const result = alignmentResults[e.target.value];
                                    setChromosome(result.chromosome);
                                    setPosition(result.position);
                                    setSelectedAlignment(e.target.value);
                                    setAlignmentOpen(false);
                                }}
                                displayEmpty
                                autoWidth
                                open={alignmentOpen}
                                onClose={() => setAlignmentOpen(false)}
                                onOpen={() => setAlignmentOpen(true)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            width: 200,
                                            marginTop: 8
                                        },
                                    },
                                }}
                            >
                                {alignmentResults.map((result, index) => (
                                    <MenuItem key={index} value={index} style={{ justifyContent: 'center' }}>
                                        {result.chromosome}:{result.position}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}

                <div style={fieldContainer}>
                    <Typography variant="subtitle1" style={descriptionStyle}>Select chromosome:</Typography>
                    <FormControl style={formControl}>
                        <InputLabel id="chromosome-select-label">Chromosome</InputLabel>
                        <Select
                            labelId="chromosome-select-label"
                            id="chromosome-select"
                            value={chromosome}
                            onChange={e => {
                                setChromosome(e.target.value);
                                resetSequenceField(); // Reset sequence field
                                resetAlignmentResult(); // Reset alignment result
                                resetLocationSwitches(); // Reset location switches
                                setSearchError(null); // Reset search error

                            }}
                        >
                            {[...Array(22).keys()].map(i => <MenuItem key={`chr${i+1}`} value={`chr${i+1}`}>{`chr${i+1}`}</MenuItem>)}
                            <MenuItem value={"chrX"}>chrX</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={fieldContainer}>
                    <Typography variant="subtitle1" style={descriptionStyle}>Enter position (hg38):</Typography>
                    <FormControl style={formControl}>
                    <TextField
                        id="position-input"
                        label="Position"
                        type="number"
                        value={position}
                        onChange={e => {
                            setPosition(e.target.value);
                            resetSequenceField(); // Reset sequence field
                            resetAlignmentResult(); // Reset alignment result
                            resetLocationSwitches(); // Reset location switches
                            setSearchError(null); // Reset search error

                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    </FormControl>
                </div>


                <Button 
                    variant="text" 
                    startIcon={<ArrowDropDownIcon style={{ transform: showInfo ? 'rotate(180deg)' : 'rotate(0deg)' }}/>}
                    style={{ color: 'grey', marginBottom: '0rem', textTransform: 'none' }}
                    disableRipple
                    onClick={() => setShowInfo(!showInfo)}
                >
                    <Typography variant="body2">
                        More Info
                    </Typography>
                </Button>

                <Collapse in={showInfo}>
                    <Typography variant="body1" style={{marginTop: '0.5rem', textAlign: 'center'}}>
                        Prediction model is trained on prime editing rates of 1182 locations in the K562 genome and corresponding chromatin features.
                        {'\n'}
                        For the online prediction, the ePRIDICT-light model is used based on a subset of the features (DNase-seq, HDAC2, H3K4me1, H3K4me2, H3K27me3, H3K9me3).
                        {'\n'}
                        Additional information and different models (more features and with other editors) can be accessed via our <Link href="https://github.com/Schwank-Lab/epridict" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                            GitHub repository
                        </Link> and publication (<Link href="https://rdcu.be/dLu0f" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                            Mathis et al. 2024
                        </Link>).

                    </Typography>
                </Collapse>

                <Button variant="contained" color="primary" onClick={fetchEpridictDataWithLoading} style={{marginTop: '1rem'}}>
                    Submit
                </Button>

                {submitLoading ? 
                    <Typography variant="subtitle1" style={{marginTop: '1rem', textAlign: 'center'}}>Loading...</Typography> 
                : null}

                {error ? 
                    error.split('\n').map((line, i) => (
                        <Typography 
                            key={i} 
                            variant="subtitle1" 
                            style={{marginTop: i === 0 ? '1rem' : '0', textAlign: 'center', color: 'red'}}
                        >
                            {line}
                        </Typography>
                    )) 
                : null}


                {submitted && !submitLoading && epridictData && (() => {
                    // create data for histogram
                    let histogramData = [];
                    for (let i = 0; i < epridictData.hist_data.length; i++) {
                        for (let j = 0; j < epridictData.hist_data[i]; j++) {
                            histogramData.push(epridictData.bin_edges[i]);
                        }
                    }

                    let predictionBinIndex = findBinForPrediction(epridictData.prediction, epridictData.bin_edges);
                    let colorArray = epridictData.bin_edges.slice(0, -1).map((d, i) => (i === predictionBinIndex ? 'red' : '#a3bed6'));

                    const histogramTrace = {
                        x: histogramData,
                        xbins: {
                            start: epridictData.bin_edges[0],
                            end: epridictData.bin_edges[epridictData.bin_edges.length - 1],
                            size: epridictData.bin_edges[1] - epridictData.bin_edges[0], // assuming equal bin width
                        },
                        marker: {
                            color: colorArray,
                            line: {
                                color: 'rgba(255, 255, 255, 1)',  // border color
                                width: 1  // border width
                            }
                        },
                        type: 'histogram'
                    };
                    return (
                        <>
                            <div style={{height: '1px', backgroundColor: '#9e9c98', marginTop: '1rem', width: '100%'}}></div>

                            <Typography variant="h5" style={{marginTop: '1rem'}}>
                                Prediction value: {epridictData.prediction !== null ? epridictData.prediction.toFixed(2) : "NaN"}
                            </Typography>
                            {/* <div style={{height: '1px', backgroundColor: '#9e9c98', margin: '20px', width: '100%'}}></div> */}

                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem'}}>
                                <Typography variant="h5" style={{fontSize: '1.2rem', fontWeight: 'normal', marginRight: '0.2rem'}}>
                                    Prediction in context of genome-wide distribution
                                </Typography>
                                <Tooltip title={<Typography style={{fontSize: '1rem'}}>Distribution shows the prediction of locations in all chromosomes of the K562 genome (sampled every 100k bp).</Typography>} arrow placement="top">
                                    <IconButton style={{ padding: 5 }}> 
                                        <InfoIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                                
                            </div>
                            <Typography variant="h5" style={{fontSize: '1.2rem', fontWeight: 'normal', marginRight: '0.2rem'}}>
                                ({epridictData.percentile !== null ? `Percentile: ${Math.floor(epridictData.percentile)}` : "NaN"})
                            </Typography>
                            <Plot 
                                data={[histogramTrace]}
                                useResizeHandler={true}
                                style={{ width: '100%', height: '60%' }}
                                
                                layout={{
                                    autosize: true,
                                    xaxis: {
                                        title: {
                                            text: 'Prediction value',
                                        },
                                        showline: true, // Show the axis line
                                        linecolor: '#9e9c98', // Color of the axis line
                                        linewidth: 2, // Width of the axis line
                                    },
                                    yaxis: {
                                        title: {
                                            text: 'Count',
                                        },
                                        tickmode: 'auto',
                                        nticks: 10,
                                        showline: true, // Show the axis line
                                        linecolor: '#9e9c98', // Color of the axis line
                                        linewidth: 2, // Width of the axis line
                                    },
                                    plot_bgcolor: 'rgba(0,0,0,0)',
                                    paper_bgcolor: 'rgba(0,0,0,0)',
                                    margin: {
                                        l: 50,  // Left margin
                                        r: 50,  // Right margin
                                        b: 50,  // Bottom margin
                                        t: 10,  // Top margin
                                        pad: 0  // Padding between edges of plot and layout boundary
                                    }
                                }}
                                config={{ staticPlot: true }} 
                            />

                            

                            <div style={{height: '1px', backgroundColor: '#9e9c98', margin: '20px', width: '100%'}}></div>
                            
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem'}}>
                                <Typography variant="h5" style={{fontSize: '1.2rem', fontWeight: 'normal', marginRight: '0.2rem'}}>
                                    Feature values of selected location
                                </Typography>
                            </div>
                            <Button 
                                variant="text" 
                                startIcon={<ArrowDropDownIcon style={{ transform: showBinInfo ? 'rotate(180deg)' : 'rotate(0deg)' }}/>}
                                style={{ color: 'grey', marginBottom: '0rem', textTransform: 'none' }}
                                disableRipple
                                onClick={() => setShowBinInfo(!showBinInfo)}
                            >
                                <Typography variant="body2">
                                More Info
                                </Typography>
                            </Button>

                            <Collapse in={showBinInfo}>
                                <Typography variant="body1" style={{marginTop: '0.5rem', textAlign: 'center'}}>
                                Bin size defines the window (e.g. 100 up and 100 downstream) over which feature values surrounding a given genomic location are averaged.
                                {'\n'}
                                Color map indicates percentile of feature values in context of values at locations sampled across the genome (every 100 kb).
                                </Typography>

                                {/*<BinFigureSVG />*/}

                                {/* <img src={YourSVGFile} alt="Your SVG Illustration" /> */}
                            </Collapse>
                            <div style={{ display: 'inline-block' }}>

                                <TableContainer component={Paper} style={{marginTop: '1rem'}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell rowSpan={1}></TableCell>
                                                <TableCell colSpan={5} align="center">
                                                    <Tooltip title={<Typography style={{fontSize: '1rem'}}>Bin size defines the window (e.g. 100 up and 100 downstream) over which values of a given feature are averaged.
                                                    {'\n'}
                                                    Color map indicates percentile of feature values in context of values at locations sampled across the genome (every 100 kb).
                                                    </Typography>} arrow placement="right">
                                                        <span>
                                                            Bin size
                                                            <IconButton style={{ padding: 5 }}> 
                                                                <InfoIcon fontSize="small"/>
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>100</TableCell>
                                                <TableCell>1000</TableCell>
                                                <TableCell>2000</TableCell>
                                                <TableCell>5000</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {featureOrder.map((featureName, index) => {
                                                const featureValues = epridictData.features[featureName];
                                                // console.log("featureName and colorScales[featureName]: ", featureName, colorScales[featureName]);

                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ fontWeight: 450 }}>
                                                            <Tooltip 
                                                                title={<Typography style={{fontSize: '1rem'}}>{tooltipDescriptions[index]}</Typography>} 
                                                                arrow 
                                                                placement="left"
                                                            >
                                                                <span>
                                                                    <Link href={featureLink[index]} target="_blank" rel="noopener noreferrer">
                                                                        {featureName}
                                                                    </Link>
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell style={{backgroundColor: colorScales[featureName](featureValues['100'])}}>{typeof featureValues['100'] === 'number' && !isNaN(featureValues['100']) ? featureValues['100'].toFixed(2) : featureValues['100']}</TableCell>
                                                        <TableCell style={{backgroundColor: colorScales[featureName](featureValues['1000'])}}>{typeof featureValues['1000'] === 'number' && !isNaN(featureValues['1000']) ? featureValues['1000'].toFixed(2) : featureValues['1000']}</TableCell>
                                                        <TableCell style={{backgroundColor: colorScales[featureName](featureValues['2000'])}}>{typeof featureValues['2000'] === 'number' && !isNaN(featureValues['2000']) ? featureValues['2000'].toFixed(2) : featureValues['2000']}</TableCell>
                                                        <TableCell style={{backgroundColor: colorScales[featureName](featureValues['5000'])}}>{typeof featureValues['5000'] === 'number' && !isNaN(featureValues['5000']) ? featureValues['5000'].toFixed(2) : featureValues['5000']}</TableCell>
                                                    </TableRow>
                                                )

                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div style={{ 
                                width: '100%',   // Set width to 100% for the parent container
                                display: 'flex', // Use flexbox to align items
                                justifyContent: 'center', // Center the child div horizontally
                                marginTop: '1rem',
                                boxSizing: 'border-box',
                                position: 'relative'
                            }}>
                                <div style={{ 
                                    width: '40%',   // Set the width of the gradient bar container
                                    position: 'relative'
                                }}>
                                    <div style={{ 
                                        height: '20px', 
                                        background: 'linear-gradient(to right, #6588fc, #FFFFFF, #fa9191)', 
                                        border: '1px solid black'
                                    }} />
                                    <div style={{ 
                                        position: 'absolute', 
                                        width: '100%', 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        padding: '0 0px', 
                                        marginTop: '5px'
                                    }}>
                                        <div style={{ fontFamily: 'Arial, sans-serif' }}>Min</div>
                                        <div style={{ fontFamily: 'Arial, sans-serif' }}>Avg</div>
                                        <div style={{ fontFamily: 'Arial, sans-serif' }}>Max</div>
                                    </div>
                                </div>
                            </div>
                            

                        </>
                    );
                })()}

            </div>
            
        </div>
    )
}
