import {Parser} from "json2csv";

const jsonToCsv = dataframe => {

    const opts = {
        fields: dataframe.schema.fields.map(e => e.name)
    }

    const parser = new Parser(opts);
    return parser.parse(dataframe.data)
}


const round = (value, decimals) => {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export {jsonToCsv, round}