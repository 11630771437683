import HomePridict2 from "./comps/HomePridict2";
import Navbar from "./comps/Navbar";
import EPRIDICT from "./comps/EPRIDICT";  // Import the new component
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {DataContextProvider} from "./context/dataContext";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AboutPage from "./comps/AboutPage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1D2F6F",
            contrastText: "#FFF"
        },
        secondary: {
            main: "#DEEBF7",
            contrastText: "#000"
        },
        background: {
            default: "#F5F5F5",
            paper:"#F5F5F5",
        },
        text:{
            primary:"#000",
            secondary:"#FFF"
        }
    },
    typography: {
        fontFamily: "Rubik"
    }
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <DataContextProvider>
                <Router>
                    <Navbar/>
                    <Switch>
                        <Route path="/" exact component={HomePridict2} />
                        <Route path="/about" exact component={AboutPage} />
                        <Route path="/epridict" exact component={EPRIDICT} />
                        <Route path="/pridict2" exact component={HomePridict2} />
                        
                    </Switch>
                </Router>
            </DataContextProvider>
        </ThemeProvider>
    );
}

export default App;