import {Typography} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, {useContext} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {DataContext} from "../context/dataContext";
import Tooltip from '@mui/material/Tooltip';
import {round} from "../utils/converters";
import { AiOutlineInfoCircle } from 'react-icons/ai';



const useStyles = makeStyles(theme => ({

    root: ({giveStaticHeight}) => ({
        overflowY: "auto",
        height: giveStaticHeight ? "" : "400px",
        marginBottom: "10%",
    }),

    table: props => ({
        minWidth: props.giveStaticHeight ? 650 : "",
    }),
    tableHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: "#000",

        borderBottom:"2px solid #90AEC2",
    },
    tableRowSelected: {
        backgroundColor: theme.palette.secondary.main,

    }
}));


export default function EditingRatesTable() {

    const {editingTableData} = useContext(DataContext)
    const theme = useTheme();
    const classes = useStyles({giveStaticHeight: editingTableData.length < 7});
    return ((
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0 auto"
        }}>
            <Tooltip title={<Typography fontSize={30} align="center">Editing rates of pegRNAs tested in self-targeting library (Library 1) from Mathis et al., Nature Biotechnology, 2023.</Typography>} arrow placement="top">
                <div>
                    <Typography variant={"h4"} style={{marginBottom: "1%", marginTop: "5%"}}>
                        Library editing rates <AiOutlineInfoCircle />
                    </Typography>
                </div>
            </Tooltip>
            <br/>
            <TableContainer component={Paper} className={classes.root}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="center">Editing <br/> in %
                                </TableCell>
                            <TableCell className={classes.tableHeader} align="center">Unintended <br/> editing in %
                                </TableCell>
                            <TableCell className={classes.tableHeader} align="center">Spacer<br/>("g" + 19bp)&nbsp;</TableCell>
                            <TableCell className={classes.tableHeader} align="center">RTT Length</TableCell>
                            <TableCell className={classes.tableHeader} align="center">PBS Length</TableCell>
                            <TableCell className={classes.tableHeader} align="center">
                                Editing Position <br/> (to nick)&nbsp;
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="center">Target Strand</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {editingTableData.map((e, i) => (
                            <TableRow key={i} style={{backgroundColor: i % 2 === 0 ? "#FFF" : theme.palette.secondary.main}}>
                            
                                <TableCell align="center">{round(e["avgEdited"],2)}</TableCell>
                                <TableCell align="center">{round(e["avgUnedited"],2)}</TableCell>
                                <TableCell align="center">{e["protospacer"]}</TableCell>
                                <TableCell align="center">{e["rtlength"]}</TableCell>
                                <TableCell align="center">{e["pbslength"]}</TableCell>
                                <TableCell align="center">{e["editingPosition"]}</TableCell>
                                <TableCell align="center">{e["targetStrand"]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </div>

    ));
}

