import { Typography } from '@material-ui/core';
import schwankimage from "../data/Schwanklab_Logo.png"
import krauthammerimage from "../data/Krauthammerlab_Logo.png"
export default function AboutPage() {

    const container = {
        display: 'flex',
        padding:"5% 10%"
    }

    return (

        <div style={container}>
            <Typography variant={"h6"} align="center">
            Here we provide different prime editing prediction tools based on sequence-context/pegRNA design (PRIDICT and PRIDICT2.0), as well as chromatin-context based prediction (ePRIDICT).<br/>
            PRIDICT was built as part of the study "<u><a href="https://www.nature.com/articles/s41587-022-01613-7" target="_blank" rel="noopener noreferrer">Predicting prime editing efficiency and product purity by deep learning</a></u>" by Mathis and Allam <i>et al.</i>. <br/>
            PRIDICT2.0 is an updated model with improved performance in the prediction of longer (up to 15 bp) edits and of mismatch repair proficient (K562) cell contexts.<br/>
            ePRIDICT is a prediction model that uses chromatin-context data of K562 from public repositories (ENCODE), to predict how local chromatin-context influences prime editing efficiency.<br/>
            PRIDICT2.0 and ePRIDICT are part of the study "<u><a href="https://rdcu.be/dLu0f" target="_blank" rel="noopener noreferrer">Machine learning prediction of prime editing efficiency across diverse chromatin contexts</a></u>" by Mathis <i>et al.</i>.<br/>
            All models were built in a collaboration between the Schwank lab (<a href="https://schwanklab.org/"><u>schwanklab.org</u></a>) and the Krauthammer lab (<a href="https://krauthammerlab.ch/"><u>krauthammerlab.ch</u></a>).<br/>
            Further information can be found on our GitHub pages of <a href="https://github.com/uzh-dqbm-cmi/PRIDICT" target="_blank" rel="noopener noreferrer"><u>PRIDICT</u></a>, 
            <a href="https://github.com/uzh-dqbm-cmi/PRIDICT2" target="_blank" rel="noopener noreferrer"><u> PRIDICT2.0</u></a> and 
            <a href="https://github.com/Schwank-Lab/epridict" target="_blank" rel="noopener noreferrer"><u> ePRIDICT</u></a>, including guidance on how to run the models in batch-mode on your local machine.<br/>
            <a href="https://schwanklab.org/"><img src={schwankimage} height={200} width={200} alt="Schwank Lab Logo"/></a><a href="https://krauthammerlab.ch/"><img src={krauthammerimage} height={200} width={200} alt="Krauthammer Lab Logo"/></a>
            </Typography>
            
        </div>
        
    )
}
