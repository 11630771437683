import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LogoIcon from "../assets/icons/20210629_PRIDICT_Design.svg";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logoContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
        height: 55,
        maxWidth: "100%",
    },
    button: {
        textTransform: "none"
    },
}));

export default function Navbar() {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const drawer = (
        <div>
            <List>
                <ListItem button onClick={() => {
                    history.push('/');
                    window.location.reload();
                }}>
                    <ListItemText primary="PRIDICT" />
                </ListItem>
                <ListItem button component={Link} to="/about">
                    <ListItemText primary="About" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <AppBar position="static" color={"secondary"}>
                <Toolbar>
                    <div className={classes.logoContainer}>
                        <img
                            src={LogoIcon}
                            alt="PRIDICT Logo"
                            className={classes.logo}
                            style={{ maxWidth: isMobile ? "85%" : "100%" }}
                        />
                    </div>
                    {isMobile ? (
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                variant="temporary"
                                anchor="right"
                                open={open}
                                onClose={handleDrawerToggle}
                            >
                                {drawer}
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => {
                                    history.push('/');
                                    window.location.reload();
                                }}
                                color="inherit"
                            >
                                PRIDICT2.0
                            </Button>
                            <Link to="/epridict">
                                <Button color="inherit" style={{ textTransform: 'none' }}>ePRIDICT</Button>
                            </Link>
                            <Link to="/about">
                                <Button color="inherit">About</Button>
                            </Link>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}
