import {createContext, useState} from "react"
import axios from "axios";
import {API_URL} from "../config";
import {toast} from "react-toastify";
import {jsonToCsv} from "../utils/converters";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { scaleLinear } from 'd3-scale';



const notify = msg => {
    toast(msg)
}

export const DataContext = createContext(undefined);

export function DataContextProvider({children}) {

    // State for RNATable
    const [rnaData, setRnaData] = useState({schema: {}, data: []});

    // State for NickingTable
    const [nickingData, setNickingData] = useState(null)


    // State for TextArea
    const [textAreaValue, setTextAreaValue] = useState("")

    // State for SwitchGroup
    const [switchData, setSwitchData] = useState([false, false, false, false]);


    // State for Summary
    const [summaryData, setSummaryData] = useState({rna: null, nick: null});


    // To show Nicking Table after click on RNA Table
    const [showNickingTable, setShowNickingTable] = useState(false)

    // To move to summary table after selecting nicking guide
    const [moveSummaryTable, setMoveToSummaryTable] = useState(false)

    // Primer data
    const [primer, setPrimer] = useState(null)

    // State for loader
    const [loading, setLoading] = useState(false)

    // DTH or HTD
    const [switchState, setSwitchState] = useState(false)

    // To query data for editing ta
    const [editingTableData, setEditingTableData] = useState([])


    const [showVariants, setShowVariants] = useState(false)

    const [selectedRNA, setSelectedRNA] = useState(null)

    // State for chromosome and position
    const [chromosome, setChromosome] = useState('chr3')
    const [position, setPosition] = useState('44843504')

    // State for epridictData
    const [epridictData, setEpridictData] = useState(null)

    //initialize error states to catch them from backend
    const [error, setError] = useState(null);

    const [colorScales, setColorScales] = useState({});

    // Add a new state for alignment results
    const [alignmentResults, setAlignmentResults] = useState([]);

    // Function to fetch alignment results from the backend
    const fetchAlignmentResults = (sequence) => {
        const url = `${API_URL}align/`;

        const body = {
            sequence: sequence,
        };

        setLoading(true);

        return axios.post(url, body)
            .then((res) => {
                setLoading(false);
                if (res.data.error) {
                    setAlignmentResults([]);
                    setError(res.data.error);
                    return res.data.error;
                } else {
                    setAlignmentResults(res.data.results);
                    setError(null);
                    return null;
                }
            })
            .catch((err) => {
                setLoading(false);
                const errMsg = err.response?.data?.error;
                notify(errMsg);
                setError(errMsg);
                return errMsg;
            });
    };

    // Getting dataframes from epridict
    const fetchEpridictData = () => {
        if (!chromosome || !position) {
            notify("Enter both chromosome and position first")
            return
        }
    
        const url = `${API_URL}epridict`
        const body = {
            chromosome: chromosome,
            position: position,
        }
    
        setLoading(true)
    
        return axios.post(url, body)
            .then((res) => {
                setLoading(false)
                if (res.data.error) {
                    setEpridictData(null);
                    setError(res.data.error);
                    return res.data.error;  // Return the error message
                } else {
                    setEpridictData(res.data);
                    setError(null);

                    // Extract the features stats
                    const featuresStats = res.data.features_stats;

                    // Create a color scale for each feature
                    const colorScales = {};
                    for (let feature in featuresStats) {
                        const stats = featuresStats[feature];
                        colorScales[feature] = scaleLinear()
                            .domain([stats.min, stats.mean, stats.max])
                            .range(["#6588fc", "white", "#fa9191"]);
                    }

                    setColorScales(colorScales);  // Save the color scales in the state
                    // console.log("colorScales set: ", colorScales);

                    return null;  // Return null if there's no error
                }
            })
            .catch((err) => {
                setLoading(false)
                const errMsg = err.response?.data?.error;
                notify(errMsg)
                setError(errMsg);  // Set the error state
                return errMsg;  // Return the error message
            })
    }

    // Getting dataframes from backend server

    const fetchDataframes = (isPridict2Selected) => {

        const url = `${API_URL}ml`
        const body = {
            strand: textAreaValue,
            isPridict2Selected: isPridict2Selected
        }

        if (textAreaValue.length === 0) {
            notify("Enter a sequence first")
            return
        }

        setLoading(true)
        let startTime = new Date().getTime();

        axios.post(url, body)
            .then((res) => {


                let peg = JSON.parse(res.data.peg);
                let nick = JSON.parse(res.data.nick);
                let primerData = JSON.parse(res.data.primer);


                peg.data = peg.data.map(e => {
                    return {...e, selected: false}
                })
                // Giving nick and peg an additional property
                // of selected to highlight a row
                // when clicked
                nick.data = nick.data.map(e => {
                    return {...e, selected: false}
                })

                setSummaryData(prev => ({...prev, primer: primerData.data[0]}))

                setPrimer(primerData)
                setRnaData(peg)
                setNickingData(nick)

                setLoading(false)

                let requestTime = new Date().getTime() - startTime;

                localStorage.setItem("estTime", requestTime)

            })
            .catch((err) => {
                    setLoading(false)
                    const errMsg = err.response?.data?.error;
                    notify(errMsg)
                }
            )
    }

    const getZipFile = () => {

        if (rnaData.data.length === 0) {
            notify("Generate some data first")
            return;
        }

        let primerCSV = jsonToCsv(primer)
        let nickCSV = jsonToCsv(nickingData)
        let pegCSV = jsonToCsv(rnaData)

        let zip = new JSZip();


        zip.file("pegRNA_dataframe.csv", pegCSV);
        zip.file("nickingguide_dataframe.csv", nickCSV)
        zip.file("NGS_primer3.csv", primerCSV)

        let currentdate = new Date();
        let datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + "--"
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds();


        zip.generateAsync({type: "blob"}).then(function (content) {
            FileSaver.saveAs(content, `pegRNA_prediction_results_${datetime}.zip`);
        });


    }

    const data = {
        rnaData, setRnaData,
        nickingData, setNickingData,
        textAreaValue, setTextAreaValue,
        switchData, setSwitchData,
        summaryData, setSummaryData,
        showNickingTable, setShowNickingTable,
        moveSummaryTable, setMoveToSummaryTable,
        fetchDataframes, getZipFile,
        loading, setLoading,
        switchState, setSwitchState,
        editingTableData, setEditingTableData,
        showVariants, setShowVariants,
        selectedRNA, setSelectedRNA,
        chromosome, setChromosome,
        position, setPosition,
        fetchEpridictData, epridictData, setEpridictData,
        error, setError,
        colorScales, setColorScales,
        alignmentResults, setAlignmentResults,
        fetchAlignmentResults

    }


    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    );
}