import {CircularProgress, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";

function CustomLoader({ isPridict2Selected = false}) {
    const [progress, setProgress] = useState(0);

    let estTime = localStorage.getItem("estTime")
    estTime = estTime ? estTime : 50000; // set default to 50 seconds
    let step = estTime ? (estTime / 100) : null;


    useEffect(() => {

        if (estTime) {
            const timer = setInterval(() => {
                setProgress(prev => prev + 1)
            }, step)

            return () => {
                clearInterval(timer);
            };
        }

    }, [estTime, step])

    return (
        <>
            <Typography align="center" style={{ marginBottom: '0rem' }}>
                Running {isPridict2Selected ? "PRIDICT2.0" : "PRIDICT"}...
            </Typography>
            {
                !estTime ||	progress >= 100 
                    ?
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <CircularProgress/>
                            <Typography> Processing sequence</Typography>
                        </div>

                    </>

                    :
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <Typography align="center">Estimated time:<br/> {Math.round(estTime / 1000)} seconds</Typography>
                            <CircularProgress variant={"determinate"} value={progress}/>
                            <Typography> {progress}% </Typography>
                        </div>
                    </>
            }
        </>
    );
}

export default CustomLoader;