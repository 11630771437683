import React, {useContext, useEffect, useState} from "react";
import {
    FormControlLabel,
    Switch,
    Grid,
    Typography,
    Paper, TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {API_URL} from "../config";
import axios from "axios";
import Autocomplete from '@material-ui/lab/Autocomplete'
import {DataContext} from "../context/dataContext";
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    shown: {
        display: ""
    },
    hidden: {
        display: "none"

    }
}));


export default function VariantsDropdown() {

    const {
        setTextAreaValue, switchState,
        setSwitchState, setEditingTableData,
        setSwitchData, setShowNickingTable,
        showVariants, setShowVariants,
    }
        = useContext(DataContext)


    const [menuItems, setMenuItems] = useState([])
    const [variantNames, setVariantNames] = useState([])

    const [currentVariant, setCurrentVariant] = useState(null)
    const [currentGene, setCurrentGene] = useState(null)


    const classes = useStyles();


    const getGeneNames = (val) => {
        const url = `${API_URL}genename`
        if (currentVariant) {
            axios.post(url, {gene: val})
                .then(res => {
                    let data = JSON.parse(res.data)


                    if (data) data = data.map(e => ({
                        name: e[0],
                        dth: e[1],
                        htd: e[2]
                    }))
                    else data = []


                    setVariantNames(data)
                })
                .catch(err => {
                    console.log({...err})
                })
        }
    }

    const getVariantNames = () => {
        const url = `${API_URL}genes`;
        axios.get(url)
            .then(res => {
                let data = JSON.parse(res.data)
                data = data.map(e => e[0])
                setMenuItems(data)
            })
            .catch(err => {
                console.log({...err})
            })

    }


    const handleChangeVariant = (e, v) => {
        setCurrentVariant(v);
    }

    const handleChangeGene = (e, v) => {
        setCurrentGene(v);
    }


    useEffect(() => {
        getVariantNames()
    }, [])

    useEffect(() => {
        getGeneNames(currentVariant)

    }, [currentVariant])

    useEffect(()=>{

        if(!showVariants) setSwitchState(false)

        // setCurrentVariant("")
        // setCurrentGene([])
    }, [showVariants])


    useEffect(() => {
        if (currentGene) {
            let target = variantNames.filter(e => e.name === currentGene.name)
            target = target[0]
            if (!switchState) {
                setTextAreaValue(target?.dth)
            } else {
                setTextAreaValue(target?.htd)
            }

            const url = `${API_URL}info`
            if (currentVariant) {
                axios.post(url, {variant: currentGene.name})
                    .then(res => {
                        let data = JSON.parse(res.data)
                        if (data) data = data.map(e => e.fields)
                        else data = []


                        setEditingTableData(data)
                    })
                    .catch(err => {
                        console.log({...err})
                    })
            }

        }
    }, [currentGene, setTextAreaValue, switchState, currentVariant, setEditingTableData, variantNames]); // Added missing dependencies



    return (
        <>

            <Paper elevation={4} style={{padding: "4% 14%", borderRadius: 10}}>
            
            <Tooltip title={<Typography fontSize={30} align="center">Select ClinVar variants which were part of the self-targeting library</Typography>} arrow placement="top">
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                onChange={() => {
                                    setSwitchData(() => [false, false, false, false])
                                    setShowVariants(prev => !prev)
                                    setTextAreaValue("")
                                    setShowNickingTable(false)

                                }}
                                checked={showVariants}

                            />
                        }
                        label="ClinVar Library Variants"
                    />
                </div>
            </Tooltip>

                <div className={showVariants ? classes.shown : classes.hidden}>

                    <Typography>Gene</Typography>
                    <Autocomplete
                        onOpen={e => {
                            if (menuItems?.length === 0) {
                                getVariantNames()
                            }
                            // setTextAreaValue("")
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            // if (reason !== 'reset') {
                            //     setVariantNames([])
                            // }
                        }}
                        onChange={handleChangeVariant}
                        options={menuItems}
                        getOptionLabel={e => e}
                        renderInput={(params) => <TextField {...params} variant="outlined"/>}
                    />

                    <Typography>Variant</Typography>
                    <Autocomplete
                        onChange={handleChangeGene}
                        options={variantNames}
                        getOptionLabel={e => e.name}
                        renderInput={(params) => <TextField {...params} variant="outlined" id="geneTextField" name="geneTextField"/>}
                    />
                </div>


            </Paper>
            <Tooltip title={<div><Typography fontSize={30} align="center">Select "Correct Disease" to display editing rates in self-targeting library of selected variant.</Typography></div>} arrow>
                <div>
                    <Paper elevation={4}
                           style={{marginTop: "10%", padding: "4% 2%", borderRadius: 10}}
                           className={showVariants ? classes.shown : classes.hidden}

                    >
                        <Grid
                            direction={"row"}

                            component="label"
                            justifyContent={"center"}
                            container
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography style={{fontSize:16}}>
                                    Introduce <br/> Disease
                                </Typography>
                            </Grid>

                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Switch
                                    color="primary"
                                    checked={switchState}
                                    onClick = {() => {
                                        setSwitchState(e => !e)
                                    }}
                                    onChange={() => {
                                        if (currentGene) {
                                            let target = variantNames.filter(e => e.name === currentGene.name)
                                            target = target[0]
                                            if (switchState) {
                                                setTextAreaValue(target?.dth)
                                            } else {
                                                setTextAreaValue(target?.htd)
                                            }
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                <Typography style={{fontSize:16}}>
                                    Correct <br/> Disease
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Tooltip>
        </>
    );
}
