import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import SwitchGroup from "./SwitchGroup";
import { makeStyles } from "@material-ui/core/styles";
import TextArea from "./TextArea";
import Button from "@material-ui/core/Button";
import RNATable from "./RNATable";
import { DataContext } from "../context/dataContext";
import NickingTable from "./NickingTable";
import VariantsDropdown from "./VariantsDropdown";
import Summary from "./Summary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "./CustomLoader";
import EditingRatesTable from "./EditingRatesTable";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';  // Import Switch component
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Link from '@material-ui/core/Link';




const useStyles = makeStyles(() => ({
  root: {
    width: "90%",
    margin: "0 auto",
  },
  buttonStylesBlue: {
    backgroundColor: "#deebf7", // Update to the color of blue you want
    color: "#000000", // Set the text color to white
    padding: "3%",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
    textAlign: "center", // Center the text in the button
    textTransform: "none", 
    boxSizing: "border-box",
  },

  buttonStylesYellow: {
    padding: "3%",
    marginBottom: "5%",
    textTransform: "none",
    backgroundColor: "#FBE5D6",
  },
  variantsDropdownMargin: {
    marginBottom: "16px", // Add a margin to create a gap
  }
}));



function HomePridict2() {
  const classes = useStyles();
  const theme = useTheme();
  // const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [showInfo, setShowInfo] = useState(false);


  const {
    rnaData,
    fetchDataframes,
    // getZipFile,
    showNickingTable,
    moveSummaryTable,
    loading,
    switchState,
    textAreaValue,
    setRnaData,
    setNickingData,
    setShowNickingTable,
    setSummaryData,
  } = useContext(DataContext);

  const [isPridict2Selected, setIsPridict2Selected] = useState(true);

  const toggleSwitch = () => {  // Function to toggle switch
    setIsPridict2Selected(!isPridict2Selected);
  };

  const fetchDataframesWithSwitchState = () => {  // Modified fetchDataframes
    // Resetting the states
    setRnaData(null);
    setNickingData(null);
    setShowNickingTable(false);
    setSummaryData({rna: null, nick: null});
    // Include isPridict2Selected in the data sent to the backend
    console.log("Value of isPridict2Selected:", isPridict2Selected);

    fetchDataframes(isPridict2Selected);
  };


  // const padding = isXSmallScreen ? '0 5%' : isSmallScreen ? '0 10%' : '0 15%';

  const pegtablediv = useRef();
  const nickingtablediv = useRef();
  const summarytablediv = useRef();
  

  useEffect(() => {
    setRnaData(null);
    setNickingData(null);
    setShowNickingTable(false);
  }, [textAreaValue, isPridict2Selected, setRnaData, setNickingData, setShowNickingTable]);

  return (
    <>
      <div className={classes.root}>
        <ToastContainer
          style={{
            fontSize: 20,
          }}
        />

        <Grid 
          container 
          spacing={0} 
          direction={isSmallScreen ? 'column' : 'row'} 
          justifyContent={isSmallScreen || isMediumScreen ? 'center' : 'space-between'} 
          alignItems={isSmallScreen || isMediumScreen ? 'center' : 'flex-start'}
          >
          <Grid
            item={true}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ padding: "0 25%", marginBottom: "2%" }}
          >
            
            <SwitchGroup />
          </Grid>
          
          <Grid container 
            direction="column" item={true} xl={2} lg={2} md={4} sm={8} xs={12} style={{marginBottom: "3%"}}>
            <div className={classes.container}>
              <Grid item className={classes.variantsDropdownMargin}>
                <VariantsDropdown />
              </Grid>
            
              <Grid item style={{ textAlign: "center", marginBottom: "3%" }}>
              
                <Tooltip title={<Typography fontSize={30} align="center">Access our complementary model based on chromatin-context based prediction of prime editing efficiency.</Typography>} arrow placement="top">
                  <a 
                    href="https://pridict.it/epridict"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Button
                      size={"large"}
                      variant={"contained"}
                      className={classes.buttonStylesBlue}
                      style={{ marginBottom: "5%" }}
                    >
                      Link to ePRIDICT<br />(chromatin-based prediction)
                    </Button>
                  </a>
                </Tooltip>
              </Grid>
            </div>
          </Grid>

          <Grid 
            item={true} 
            xl={6} 
            lg={6} 
            md={12} 
            sm={12} 
            xs={12} 
            style={{marginBottom: "3%"}}
          >
            <TextArea />
          </Grid>

          <Grid
            item={true}
            xl={2}
            lg={2}
            md={4}
            sm={8}
            xs={12}
            style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "3%" }}
            >

            
            {loading ? (
              <>
                <CustomLoader isPridict2Selected={isPridict2Selected}/>
              </>
            ) : (
              <>
                <Paper elevation={4} style={{ padding: "2% 14%", borderRadius: 10, marginBottom: "3%" }}>
                  <div style={{ display: "flex", justifyContent: "center", marginBottom: "0px" }}>
                    <Typography 
                      variant="subtitle1" 
                      style={{ fontSize: "1.2rem" }}
                    >
                      Choose model:
                    </Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography 
                      variant="subtitle1" 
                      style={{ marginRight: 4, color: isPridict2Selected ? "lightgrey" : "black" }}
                    >
                      PRIDICT
                    </Typography>
                    <Switch
                      checked={isPridict2Selected}
                      onChange={toggleSwitch}
                      name="isPridict2Selected"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Typography 
                      variant="subtitle1" 
                      style={{ marginLeft: 4, color: isPridict2Selected ? "black" : "lightgrey" }}
                    >
                      PRIDICT2.0
                    </Typography>
                  </div>
                </Paper>

                <Button 
                  variant="text" 
                  startIcon={<ArrowDropDownIcon style={{ transform: showInfo ? 'rotate(180deg)' : 'rotate(0deg)' }}/>}
                  style={{ color: 'grey', marginBottom: '0rem', textTransform: 'none' }}
                  disableRipple
                  onClick={() => setShowInfo(!showInfo)}
                >
                  <Typography variant="body2">
                    More Info
                  </Typography>
                </Button>
                <Collapse in={showInfo}>
                  <Typography variant="body1" style={{marginTop: '0.5rem',marginBottom: '1rem', textAlign: 'center'}}>
                    PRIDICT2.0 (<Link href="https://rdcu.be/dLu0f" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                    Mathis et al. 2024
                    </Link>) is an updated model with improved and more robust prediction performance, especially for longer edits (up to 15 bp), over the original PRIDICT (<Link href="https://www.nature.com/articles/s41587-022-01613-7" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                    Mathis et al. 2023
                    </Link>) and also has the added function to predict efficiencies in a mismatch-repair proficient context (K562). For more information about PRIDICT2.0 and its complementary, chromatin-context based prediction model "ePRIDICT", check out our <Link href="https://rdcu.be/dLu0f" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                    publication
                    </Link>.
                  </Typography>
                </Collapse>

                <Button
                  size={"large"}
                  variant={"contained"}
                  className={classes.buttonStylesYellow}
                  onClick={fetchDataframesWithSwitchState}
                >
                  Run prediction
                  <br />
                  (Design pegRNAs)
                </Button>        

              </>
            )}
          </Grid>

          <Grid
            item={true}
            xl={12}
            lg={12}
            md={12}
            sm={10}
            xs={10}
            style={{ padding: "0 15%" }}
          >
            {switchState && <EditingRatesTable />}
          </Grid>

          <Grid
            item={true}
            xl={12}
            lg={12}
            md={12}
            sm={10}
            xs={10}
            style={{ padding: isLargeScreen ? "0 15%" : "0" }}
            >
            {rnaData?.data.length > 0 && rnaData ? (
              <div ref={pegtablediv}>
                <RNATable isPridict2Selected={isPridict2Selected} />
                {pegtablediv.current && pegtablediv.current.scrollIntoView({ behavior: "smooth", block: "center"})}

              </div>
            ) : null}
          </Grid>

          <Grid
            item={true}
            xl={12}
            lg={12}
            md={12}
            sm={10}
            xs={10}
            style={{ padding: isLargeScreen ? "0 15%" : "0" }}
          >
            {showNickingTable && (
              <div ref={nickingtablediv}>
                <NickingTable />
                {nickingtablediv.current && nickingtablediv.current.scrollIntoView({ behavior: "smooth", block: "center"})}

              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div ref={summarytablediv}>
        {showNickingTable && <Summary isPridict2Selected={isPridict2Selected}/>}
        {moveSummaryTable && summarytablediv.current && summarytablediv.current.scrollIntoView({ behavior: "smooth", block: "center"})}

      </div>
    </>
  );
}

export default HomePridict2;
