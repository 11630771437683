import {Typography} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DataContext} from "../context/dataContext";
import {useTheme} from '@material-ui/core/styles';
import {useEffect, useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { BsArrowDownCircle } from 'react-icons/bs';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({

    root: {
        overflowY: "auto",
        height: "400px",
        marginBottom: "10%",
    },

    table: {
        minWidth: 650,
    },
    tableHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,

        borderBottom:"2px solid #90AEC2",
    },
    tableRowSelected: {
        backgroundColor: theme.palette.secondary.main,

    }
}));

const BlackCheckbox = withStyles({
    root: {
      color: 'black',
      '&$checked': {
        color: 'black',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);



export default function NickingTable() {


    const classes = useStyles();
    const theme = useTheme();
    
    
    const {
        nickingData,
        setSummaryData,
        selectedRNA,
        setMoveToSummaryTable
        
        
    } = useContext(DataContext);
    const [nickCopy, setNickCopy] = useState({...nickingData});


    const handleClick = index => {


        let copy = [...nickCopy.data]

        copy = copy.map((e, i) => {
            if (e.selected) {
                return {...e, selected: false}
            }
            return i === index ? {...e, selected: true} : {...e, selected: false}
        })


        if (copy[index].selected) {
            setSummaryData(prev => ({...prev, nick: copy[index]}))
            setMoveToSummaryTable(true)
        }
        else {
            setSummaryData(prev => ({...prev, nick: null}))
            setMoveToSummaryTable(false)
        }

        setNickCopy(prev => ({...prev, data: copy}))
        // setSummaryData(prev => ({...prev, nick: copy[index]}))
    }

    useEffect(() => {

        let copy = [...nickingData.data].filter(e => (e.Target_Strand) !== (selectedRNA["Target-Strand"]))
        setNickCopy(prev => ({...prev, data: copy}))
    }, [selectedRNA, nickingData.data])

    useEffect(() => {
        if(nickingData?.data?.length === 0 || !nickingData?.data){
            setNickCopy(prev => ({...prev, data: []}))
        }
    }, [nickingData])


    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0 auto"
        }}>
            <Tooltip title={<Typography fontSize={30} align="center">Select a nicking guide and scroll down to summary table.</Typography>} arrow placement="top">
                <div>
                    <Typography variant={"h4"} style={{marginBottom: "2%"}}>
                        Nicking Guide <AiOutlineInfoCircle />
                    </Typography>
                </div>
            </Tooltip>
            <br/>
            <TableContainer component={Paper} className={classes.root}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="center">Select<br/>(optional)</TableCell>
 
                            <TableCell className={classes.tableHeader} align="center">Spacer</TableCell>
                            <TableCell className={classes.tableHeader} align="center">Target Strand&nbsp;</TableCell>
                            <Tooltip title={<Typography fontSize={30} align="center">Distance of nick induced by nicking guide in relation to pegRNA edit.</Typography>} arrow placement="top">

                                <TableCell className={classes.tableHeader} align="center">Nick Position to
                                edit</TableCell>
                            </Tooltip>
                            <TableCell className={classes.tableHeader} align="center">PE3b</TableCell>
                            <TableCell className={classes.tableHeader} align="center">DeepSpCas9 Score <br/> (Kim et al.
                                2019)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nickCopy?.data?.map((e, i) => {

                            return(
                                <TableRow key={i}
                                          onClick={() => {
                                              handleClick(i)
                                          }}
    
                                          selected={e.selected}
                                          style={{
                                              backgroundColor: e.selected
                                                  ?
                                                  "#FBE5D6"
                                                  :
                                                  i % 2 === 0 ? "#FFF" : theme.palette.secondary.main,
    
                                              color:"#FFF"
                                          }}
                                >
                                    <TableCell align="center">
                                        <BlackCheckbox checked={e.selected} onChange={() => handleClick(i)} />
                                    </TableCell>
                                    <TableCell align="center">{e["Nicking-Protospacer"]}</TableCell>
                                    <TableCell align="center">{e["Target_Strand"]}</TableCell>
                                    <TableCell align="center">{e["Nicking-Position-to-edit"]}</TableCell>
                                    <TableCell align="center">{e["PE3b"]}</TableCell>
                                    <TableCell align="center">{e["DeepCas9score"]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>

    );
}
